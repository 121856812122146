import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanVistService } from "../../services/plan-visit-demo/plan-vist.service";
import { SkanrayToastService } from "../../services/common/skanray-toast.service";
import { Observer } from "rxjs";
import { CustomerEnquiryService } from "../../services/manage-web-enquiry/customerEnquiry.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-confirmation-box",
  templateUrl: "./confirmation-box.component.html",
  styleUrls: ["./confirmation-box.component.scss"],
})
export class ConfirmationBoxComponent implements OnInit {
  feedBackForm: FormGroup;
  remarkForm: FormGroup;
  EmployeeRemarkForm: FormGroup;
  convertToLeadForm: FormGroup;
  statusDropDownList: any[] = [
    { name: "COMPLETED" },
    { name: "POSTPONED" },
    { name: "CANCELLED" },
  ];
  userListDropDown: any[] = [];
  employeeRemarkDropDown: any[] = [
    { value: "Not a genuine customer" },
  ];
  @Input() isRemarks: boolean = false;
  @Input() isEmployeeRemark: boolean = false;
  @Input() isVisit: boolean = false;
  @Input() convertToLeadID: string = "";
  @Input() cityId: string = "";
  @Input() vistData: any;
  @Input() message: string = "Are you sure you want to Activate?";
  @Output() confirm = new EventEmitter<boolean>();
  @Output() convertToLeadData = new EventEmitter<any>();
  @Output() remarkData = new EventEmitter<any>();
  @Output() onRemarkClosed = new EventEmitter<boolean>();
  @Input() existingRemarks: string[] = [];
  @Input() existingRemark: string;
  @Input() isRemarkClosed: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private planVistService: PlanVistService,
    private toasterService: SkanrayToastService,
    private customerEnquiryService: CustomerEnquiryService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    console.log("convertToLeadID", this.convertToLeadID, this.cityId);

    if (this.isVisit) this.feedbackInit();
    if (this.isRemarks) {
      this.remarkInit();
      // this.getRemark(this.convertToLeadID);
    }
    if (this.isEmployeeRemark) {
      this.employeeRemarkInit();
      if(this.existingRemark) this.setEmployeeRemark();
    }
    if (this.convertToLeadID) {
      this.convertToLeadDropDown();
      this.initializeConvertToLeadForm();
    }
  }

  onConfirm(): void {
    this.confirm.emit(true);
    this.activeModal.close();
  }

  onConvertToLeadConfirm(): void {
    console.log("this.convertToLeadForm", this.convertToLeadForm.valid);
    console.log("this.convertToLeadForm 2", this.convertToLeadForm);

    this.convertToLeadData.emit(this.convertToLeadForm.value);
    this.activeModal.close();
  }

  onSubmit(): void {
    this.markFormGroupTouched(this.feedBackForm);
    if (this.feedBackForm?.valid) {
      this.updateFeedbackApi();
      this.confirm.emit(true);
      this.activeModal.close();
    }
  }

  onSubmitRemarks(): void {
    this.markFormGroupTouched(this.remarkForm);
    // if (this.remarkForm?.valid) {
    //   this.remarkData.emit(this.remarkForm.value);
    //   this.activeModal.close();
    // }
    if (this.remarkForm.valid) {
      const newRemark = this.remarkForm.get('remarks')?.value.trim();
      if (newRemark) {
        this.remarkData.emit({ remarks: newRemark });
        this.activeModal.close();
      } else {
        this.toastr.error('Remark cannot be empty.');
      }
    }
  }

  onSubmitEmployeeRemark(): void {
    this.markFormGroupTouched(this.EmployeeRemarkForm);
    if (this.EmployeeRemarkForm?.valid) {
      this.remarkData.emit(this.EmployeeRemarkForm.value);
      this.activeModal.close();
    }
  }

  onCloseRemarks(): void {
    if (this.existingRemarks.length){
      this.onRemarkClosed.emit(true);
    } else {
      this.toastr.error('Add Atleast one remark before closing');
    }
  }

  onCancel(): void {
    this.confirm.emit(false);
    this.activeModal.dismiss();
  }

  feedbackInit() {
    this.feedBackForm = this.formBuilder.group({
      message: [null, Validators.required],
      // status: [null, Validators.required],
    });
  }

  remarkInit() {
    this.remarkForm = this.formBuilder.group({
      remarks: [null, Validators.required],
    });
  }

  employeeRemarkInit() {
    this.EmployeeRemarkForm = this.formBuilder.group({
      remark: [null, Validators.required],
      isNotGenuineCustomer: [false, Validators.required],
    });
  }

  setEmployeeRemark() {
    this.EmployeeRemarkForm.patchValue({
      remark: this.existingRemark,
    })
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  // getRemark(id: string) {
  //   this.customerEnquiryService.getRemarks(id).subscribe({
  //     next: (response: any) => {
  //       if (response?.status === 200) {
  //         this.remarkForm.patchValue({
  //           remarks: response?.data?.remarks,
  //         });
  //       }
  //       console.log("Data sent successfully:", response);
  //     },
  //     error: (error: any) => {
  //       this.toasterSuccessORWarning(error);
  //       console.error("Error sending data:", error);
  //     },
  //     complete: () => {
  //       console.log("Observable completed");
  //     },
  //   })
  // }

  updateFeedbackApi() {
    const id = this.vistData?._id ?? "";
    this.planVistService
      .feedbackUpdateForVisit(id, this.feedBackForm.value)
      .subscribe({
        next: (response: any) => {
          if (response?.status === 200) {
            this.toasterSuccessORWarning(response);
          }
          console.log("Data sent successfully:", response);
        },
        error: (error: any) => {
          this.toasterSuccessORWarning(error);
          console.error("Error sending data:", error);
        },
        complete: () => {
          // This block will be executed when the observable completes (optional)
          console.log("Observable completed");
        },
      } as Observer<any>);
  }

  toasterSuccessORWarning(response: any) {
    this.toasterService.showToaster(response);
  }

  initializeConvertToLeadForm() {
    this.convertToLeadForm = this.formBuilder.group({
      userList: [, Validators.required],
    });
  }

  convertToLeadDropDown() {
    this.customerEnquiryService
      .getConvertToLeadDropDown(this.cityId)
      .subscribe((dropDwonData: any) => {
        console.log("convertToLeadDropDown", dropDwonData);
        this.userListDropDown = dropDwonData?.data?.users.map((data: any) => {
          return {
            ...data.productId,
            label:
              data?.firstName + data?.lastName + `(${data?.roleId[0]?.role})`,
            value: data._id,
          };
        });
      });
  }
}
