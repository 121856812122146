import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EncryptDecryptService } from '../shared/services/encryption.service';
import countryCodes from '../webenquiryform/webenquiryform.component';
import { CityService } from '../shared/services/manage-territory/city.service';
import { CustomerEnquiryService } from '../shared/services/manage-web-enquiry/customerEnquiry.service';
import { SkanrayToastService } from '../shared/services/common/skanray-toast.service';

@Component({
  selector: 'app-webenquiry-ecommerceform',
  templateUrl: './webenquiry-ecommerceform.component.html',
  styleUrls: ['./webenquiry-ecommerceform.component.scss']
})
export class WebenquiryEcommerceformComponent implements OnInit {
  webEnquiryForm: FormGroup;
  decryptedData: any;
  countryCodeDropDownList: (number | string)[] = [];
  sourceOfLead: any;
  cityList: any[] = [];
  dataLoaded = false;
  formSubTittle =
  "If you have any queries,kindly take a moment to fill up this form,and our representatives will contact you shortly.";
  isOthers: boolean = false;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private encryptDecryptService: EncryptDecryptService,
    private cityService: CityService,
    private customerEnquiryService: CustomerEnquiryService,
    private toasterService: SkanrayToastService,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["id"]) {
        const encodedId = params["id"];
        this.decryptedData = encodedId
          ? this.encryptDecryptService.decryptObject(encodedId)
          : null;
      }
      if (params["sourceOfLead"]) {
        this.sourceOfLead = params["sourceOfLead"];
      }
    })
  }

  ngOnInit(): void {
    const flattenedArray: (number | string)[] = (
      [] as (number | string)[]
    ).concat(...countryCodes);
    this.countryCodeDropDownList = [...flattenedArray];
    this.initializeForm();
    this.getAllCities();
    if (this.decryptedData) {
      const date1 = new Date(this.decryptedData.expiryDate);
      const date2 = new Date();
      date2.setHours(0, 0, 0, 0);
      if (date2 <= date1) {
        this.dataLoaded = true;
      } else this.dataLoaded = false;
    }
  }

  initializeForm() {
    this.webEnquiryForm = this.fb.group({
      companyId: [this.decryptedData.companyId, Validators.required],
      name: [, Validators.required],
      email: [, Validators.required],
      countryCode: [91, Validators.required],
      mobileNumber: [, Validators.required],
      sourceOfLead: [this.sourceOfLead, Validators.required],
      product: [null],
      message: [, Validators.required],
      city: [, Validators.required],
      cityName: [],
      geo: [],
      country: [],
      state: [],
      region: [],
      district: [],
    })
  }

  getAllCities() {
    this.cityService
      .getAllCitiesWithoutAuth({ companyId: this.decryptedData.companyId })
      .subscribe((res: any) => {
        if (res && res.data && res.data.cityDropdown) {
          this.cityList = [...res.data.cityDropdown, { name: "Others", _id: "Others" }];
        }
      })
  }

  formReset() {
    this.webEnquiryForm.reset({
      companyId: this.decryptedData.companyId,
      sourceOfLead: this.sourceOfLead
    });
  }

  onSelectCity() {
    const cityId = this.webEnquiryForm.get("city")?.value;
    if (cityId && cityId !== "Others") {
      this.isOthers = false;
      this.webEnquiryForm.get("cityName")?.setValue(null);
      this.cityService
        .getCityWithHierarchyByIdWithoutAuth(cityId)
        .subscribe((cityData: any) => {
          if (cityData && cityData.data && cityData.data.city) {
            // const city = cityData.data.city;
            // const district = city.district;
            // const state = district.state;
            // const region = state.region;
            // const country = region.country;
            // const geo = country.geo;
            const city = cityData.data.city;
            const district = {
              _id: city.district._id,
              name: city.district.name,
            };
            const state = {
              _id: city.district.state._id,
              name: city.district.state.name,
            };
            const region = {
              _id: city.district.state.region._id,
              name: city.district.state.region.name,
            };
            const country = {
              _id: city.district.state.region.country._id,
              name: city.district.state.region.country.name,
            };
            const geo = {
              _id: city.district.state.region.country.geo._id,
              geoName: city.district.state.region.country.geo.geoName,
            };

            this.webEnquiryForm.get("district")?.setValue(district._id);
            this.webEnquiryForm.get("state")?.setValue(state._id);
            this.webEnquiryForm.get("region")?.setValue(region._id);
            this.webEnquiryForm.get("country")?.setValue(country._id);
            this.webEnquiryForm.get("geo")?.setValue(geo._id);

            // this.getCitiesById(district._id);
          }
        });
    }else{
      this.isOthers = true;
      this.webEnquiryForm.get("district")?.setValue(null);
      this.webEnquiryForm.get("state")?.setValue(null);
      this.webEnquiryForm.get("region")?.setValue(null);
      this.webEnquiryForm.get("country")?.setValue(null);
      this.webEnquiryForm.get("geo")?.setValue(null);
    }
  }

  onSubmit() {
    if (this.webEnquiryForm.valid) {
      this.createWebEnquiry();
    }
  }

  createWebEnquiry() {
    const formValues = this.webEnquiryForm.value;
    const createEnquiry = {
      name: formValues.name,
      email: formValues.email,
      countryCode: formValues.countryCode.toString(),
      mobileNumber: formValues.mobileNumber,
      message: formValues.message,
      sourceOfLead: formValues.sourceOfLead,
      enquiryId: this.decryptedData._id,
      companyId: this.decryptedData.companyId,
      region: formValues.region,
      city: formValues.city,
      cityName: formValues.cityName,
      state: formValues.state,
      product: formValues.product,
      geo: formValues.geo,
      district: formValues.district,
      country: formValues.country,
    };
    this.customerEnquiryService.createEnquiry(createEnquiry).subscribe(
      (sData) => {
        this.toasterSuccess(sData);
      },
      (error) => {
        console.error("Error:", error);
        this.toasterWarning(error);
      }
    );
  }

  toasterSuccess(response: any) {
    this.toasterService.showToaster(response);
    this.formReset();
  }

  toasterWarning(response: any) {
    this.toasterService.showToaster(response);
  }
}
