import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { QueryParamsService } from "../common/queryParams.service";
@Injectable({
  providedIn: "root",
})
export class CustomerEnquiryService {
  constructor(
    private httpClient: HttpClient,
    private queryParamsService: QueryParamsService
  ) { }


  getCustomerEnquiryList(params?: any) {
    let queryParams: any = new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    let apiUrl = `${environment.serverEndPoint}/customer-enquiry/list`
    return this.httpClient.get(apiUrl, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }


  createEnquiry(data: any) {
    const apiUrl = `${environment.serverEndPoint}/customer-enquiry/create`;
    return this.httpClient.post<any>(apiUrl, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  convertToLead(id: any, _body?: any) {
    const apiUrl = `${environment.serverEndPoint}/customer-enquiry/convert/to-lead/` + id;
    return this.httpClient.put(apiUrl, _body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateRemarks(id: any, _body?: any) {
    const apiUrl = `${environment.serverEndPoint}/customer-enquiry/update/remarks/` + id;
    return this.httpClient.put(apiUrl, _body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  closeRemarks(id: any) {
    const apiUrl = `${environment.serverEndPoint}/customer-enquiry/close/remarks/` + id;
    return this.httpClient.put(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    })
  }

  getRemarks(id: any) {
    const apiUrl = `${environment.serverEndPoint}/customer-enquiry/remarks/` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getReport(params?: any) {
    let queryParams: any = new HttpParams();
    if (params && typeof params === 'object' && Object.keys(params).length) {
      queryParams = this.queryParamsService.getQueryParams(params);
    }
    const getAPI = `${environment.serverEndPoint}/customer-enquiry/customer/enquiry/export`;
    return this.httpClient.get(getAPI, {
      params: queryParams,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getConvertToLeadDropDown(id: any) {
    const apiUrl = `${environment.serverEndPoint}/users/drop-down/to-convert/lead?city=` + id;
    return this.httpClient.get(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
